<template>
	<div class="home">
		<Header></Header>
		<div class="home-contain">
			<div class="home-search">
				<div class="home-select">
					<div class="select-contain">
						<el-form>
							<el-form-item>
								<el-col :span="24" style="display:flex;">
									<el-col style="width:35%">
										<el-dropdown split-button type="primary" @command="handleCommand">
											{{ searchType }}
											<el-dropdown-menu slot="dropdown">
												<el-dropdown-item command="0">职位</el-dropdown-item>
												<el-dropdown-item command="1">公司</el-dropdown-item>
											</el-dropdown-menu>
										</el-dropdown>
									</el-col>
									<el-input v-model="input" placeholder="搜索职位、公司"></el-input>
								</el-col>
								<el-col :span="4">
									<el-button type="primary" @click="seljob">搜索</el-button>
								</el-col>
							</el-form-item>
						</el-form>
					</div>
					<div class="hot_search">
						<div class="position">
							<span>热搜职位：</span>
							<a @click="goto('jobhunting?searchKey=' + item.searchKey, {}, 2)"
								v-for="(item, index) in searchlist" :key="index">{{ item.searchKey }}</a>
						</div>
					</div>
				</div>
			</div>
			<div class="home-news">
				<div class="news-contain">
					<div class="title_left">
						<div class="el-menu">
							<div v-for="(item, index) in hylist" :key="index"
								@mouseenter="mouseover(item.auto_id,item.com_account)"
								@click="goto('jobhunting',{type:item.auto_id},2)">
								<span class="title">{{item.com_account}}</span>
								<i class="el-icon-arrow-right"></i>
							</div>
						</div>
						<div v-if="submenushow" class="el-submenu" @mouseleave="mouseout()">
							<div class="subtitle">{{com_account}}</div>
							<a v-for="(item, index) in hylist1" :key="index"
								@click="goto('jobhunting',{type:auto_id,type1:item.auto_id},2)">
								{{item.com_account}}
							</a>
						</div>
					</div>
					<div class="title_center">
						<el-row :gutter="20">
							<el-col :span="24">
								<div class="news-recruit">
									<el-carousel :interval="5000" height="277px" indicator-position="none">
										<el-carousel-item v-for="item in carousellist" :key="item.id"
											style="display: flex; justify-content: center">
											<el-image fit="fill" style="border-radius: 6px;height:277px;"
												:src="defimgurl + item.picName"></el-image>
										</el-carousel-item>
									</el-carousel>
								</div>
							</el-col>

							<!-- <el-col :span="8">
								<div v-if="userid==0" style="width:100%;height:100%;">
									<div
										style="width:100%;height:20%;display: flex;justify-content: space-between;align-items: center;background: #fff;">
										<div :class="['onlog',choice1 ?'' : 'onlog1']" @click="onChoice1(true)">企业登录
										</div>
										<div :class="choice3 ? 'sanjiao1' : 'sanjiao'"></div>
										<div :class="['onlog',choice2 ? '' : 'onlog1']" @click="onChoice2(true)">个人登录
										</div>
									</div>

									<div class="logSign">
										<div class="el-row card_input input">
											<div class="el-input el-input--prefix">
												<input type="text" class="el-input__inner" placeholder="账号"
													v-model="subfrom.mobile" />
											</div>
										</div>
										<div class="el-row card_input input">
											<div class="el-input el-input--prefix">
												<input type="password" class="el-input__inner" placeholder="密码"
													v-model="subfrom.password" />
											</div>
										</div>
										<div class="el-row card_input input">
											<div class="el-input el-input--prefix">
												<input type="text" class="el-input__inner" placeholder="验证码"
													v-model="subfrom.code" />
												<span id="canvasCode" class="code"></span>
											</div>
										</div>
									</div>
									<div class="but">
										<el-button type="primary" round @click="logininfo()">{{ logtext }}登录</el-button>
										<el-button type="success" round @click="register()">{{ logtext }}注册</el-button>
									</div>
								</div>
								<div v-else style="width:100%;height:100%;">
									<div v-if="usertype==2" class="logSign">
										<div class="el-row card_input input">
											<div class="el-input el-input--prefix">
												{{userinfo.coM_NAME}}
											</div>
										</div>
									</div>
									<div v-else class="logSign">
										<div class="el-row card_input input">
											<div class="el-input el-input--prefix">
												{{userinfo.peR_NAME}}
											</div>
										</div>
									</div>
									<div class="but">
										<el-button v-if="usertype==2" type="primary" round
											@click="goto('companycenter',{type:0},1)">企业中心
										</el-button>
										<el-button v-else type="primary" round @click="goto('usercenter',{type:0},1)">
											个人中心</el-button>
									</div>
								</div>
							</el-col> -->
						</el-row>
						<el-row :gutter="20">
							<el-col style="padding-right: 0;width: 100%;">
								<div class="news-content">
									<el-tabs v-model="activeName" type="border-card">
										<el-tab-pane class="grey" v-for="(item, index) in newstypeList1" :key="item.id"
											:name="item.id">
											<span slot="label" @mouseover="newstypeMouseover(item.id, index)"
												@click="newschage(item.id)">
												{{ item.name }}
											</span>
											<div class="news-cell">
												<div v-for="item1 in newslist" :key="item1.id" class="news-item"
													@click="goto('/info', { id: item1.id }, 2)">
													<div class="icon_yuan"></div>
													<!-- <i class="el-icon-document"></i> -->
													<div :class="[
														'news-title',
														item1.cRED == 1 ? 'red' : '',
														,
														item1.cWEIGHT == 1 ? 'weight' : '',
													]">
														{{ item1.cTITLE }}
													</div>
													<div class="news-time">
														{{ stringToDates(item1.dREDATE) }}
													</div>
												</div>
											</div>
										</el-tab-pane>
										<el-tab-pane name="more" class="more-btn"><span slot="label">
												<router-link to="/news?type=36">更多+</router-link>
											</span></el-tab-pane>
									</el-tabs>
								</div>
							</el-col>
						</el-row>
					</div>
					<div class="title_reght"></div>

				</div>
			</div>
			<div class="clear"></div>
			<div class="home-company">
				<div class="company-content">
					<div class="company-title flex2">
						<div class="name flexc">
							<img :src="require(`@/assets/img/icon/tjqy.png`)" style="width:24px;height:24px;" alt="" />
							名企招聘
						</div>

						<div style="display: flex;align-items: center;">
							<span class="more" @click="Gethotcompanylists"><i style="color:#0089FF"
									class="el-icon-refresh-right"></i> 换一换</span>
							<span style="margin:0 3px ;font-size: 14px;margin-bottom: 2px;">|</span>
							<span class="more" @click="goto('/recommend', {})">更多</span>
						</div>

					</div>
					<div class="company-cell">
						<div class="company-item" v-for="(cell, j) in companydata" :key="j"
							@click="goto('/company', { id: cell.coM_ID })">
							<div class="company-logo">
								<img :src="setcompanylogo(cell.coM_LOGO, 'deflogo')" alt="" />
							</div>
							<div class="company-name">{{ cell.coM_NAME }}</div>
							<div class="company-num">
								{{ cell.info }}
							</div>
							<div class="company-post" :class="{ hoveractive: isActive && cell.coM_ID == num }">
								<div class="post-item" v-for="(cont, n) in analysisjob(cell.info)" :key="n">
									<div class="post-name">{{ cont.postname }}</div>
									<div class="post-salary">{{ cont.salary }}</div>
								</div>
								<div class="post-more">
									<el-button size="mini" round @click="goto('/company', { id: cell.coM_ID })">
										查看更多>></el-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="home-advertisement">
				<div v-for="item in bannerlist" :key="item.id">
					<img @click="linkto(item.url)" :src="defimgurl + item.picName" style="width:100%;cursor: pointer;"
						alt="" />
				</div>
			</div>
			<div class="home-position">
				<div class="position-content">
					<div class="position-title flex2">
						<div class="name flexc">
							<img :src="require(`@/assets/img/icon/tjgw.png`)" style="width:24px;height:24px;" alt="" />
							会员招聘
						</div>
						<div style="display: flex;align-items: center;">
							<!-- <span class="more" @click="Gethotposilists"><i style="color:#0089FF"
									class="el-icon-refresh-right"></i> 换一换</span>
							<span style="margin:0 3px ;font-size: 14px;margin-bottom: 2px;">|</span>
							<span class="more" @click="goto('/companyList', {})">更多</span> -->
						</div>
						<!-- <span class="more" @click="goto('/companyList', {})">查看更多>></span> -->
					</div>
					<div class="position-cell">
						<div class="position-item" v-for="item in positiondata" :key="item.oF_ID"
							@click="goto('/company', { id: item.coM_ID })">
							<div class="position-hd">
								<img :src="require('@/assets/img/vip.png')" v-if="item.ywcount > 0"
									style="margin-right: 5px;" />
								{{ item.coM_NAME }}
							</div>
							<div class="position-bd">
								<div class="post-info">{{ item.info }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="home-links" v-show="false">
				<div class="links-content">
					<div class="links-title">友情链接</div>
					<div class="links-cell">
						<div class="cell-item" v-for="(item, index) in linksdata" :key="index">
							<router-link to="">
								<img @click="linkto(item.url)" :src="defimgurl + item.picName" alt="" />
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="home-workplace">
				<div class="workplace-comtent">
					<div class="position-title flex2">
						<div class="name flexc">
							<img :src="require(`@/assets/img/icon/tjgw.png`)"
								style="width:24px;height:24px;transform: translateY(-60px);filter: drop-shadow(#ED6817  0 60px) ;"
								alt="" />
							推荐人才
						</div>
						<div class="workplace-nav">
							<div class="workplace-item" v-for="title in newstypeList2" :key="title.id"
								@click="handleClick1(title)" :class="{ active: cur == title.id }">
								{{ title.name }}
								<div v-show="title.state"
									style="height: 2px;background-color: #F66308 ;margin-top: 3px;">
								</div>
							</div>
						</div>
						<div style="display: flex;align-items: center;">
							<span class="more" @click="goto('/resume', {})"><i style="color:#0089FF"
									class="el-icon-refresh-right"></i> 换一换</span>
							<span style="margin:0 3px ;font-size: 14px;margin-bottom: 2px;">|</span>
							<span class="more" @click="goto('/resume', {})">更多</span>
						</div>
						
					</div>
				</div>
			</div> -->
			<!-- <div class="personnel">
				<div class="personnel_details" v-for="item in personnelList" :key="item.peR_ID"
					@click="isRshow = !isRshow; uid = item.peR_ID">
					<div class="resume-logo">
						<img v-if="item.peR_GENDER == '男'" :src="require(`@/assets/img/male_img.png`)" alt="" />
						<img v-else :src="require(`@/assets/img/nv.png`)" alt="" />
					</div>
					<div>
						<div class="name">
							<span>{{ utils.formatName(item.peR_NAME) }}</span>
						</div>
						<div class="introduce">
							<span>{{ item.peR_WORK_YEARS ? item.peR_WORK_YEARS + '年以上经验' : "无工作经验" }}</span>
							<span style="margin-right:0px;"> {{ item.per_hedu }}学历</span>
						</div>
						<div class="introduce" style="color:#467DF2">
							<span>求职意向：{{ item.peR_HOPE_INDUSTRY }}</span>
						</div>
					</div>
				</div>
			</div> -->
		</div>

		<resumedetail :isRshow="isRshow" :isCall="isCall" :uid="uid"></resumedetail>
	</div>
</template>

<script>
	// @ is an alias to /src
	// import HelloWorld from '@/components/HelloWorld.vue'
	import {
		Getnewslist,
		Getadlist,
		Getadlinklist,
		Gethotcompanylist,
		Gethotposilist,
		Getrotationadvlist,
		GetAdvLinkByType,
		recPerson,
		getsearch,
		addsearch,
		GetHyList,
		getGVerify,
		GetAllHyList
	} from "@/api/home";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	import {
		isnull
	} from "@/assets/js/index";
	import {
		UserLogin,
		CompanyLogin,
		Getscanuserinfo
	} from "@/api/user";
	import resumedetail from "./resume/resumedetail.vue";
	export default {
		name: "Home",
		components: {
			resumedetail
		},
		data() {
			return {
				logtext: "企业",
				GVerifyCode: null,
				subfrom: {
					mobile: "",
					password: "",
				},
				choice1: true,
				choice2: false,
				choice3: false,
				translateNum: 0,
				timer: null,
				activeName: "39",
				defimgurl: global.baseimgurl,
				searchid: 0,
				searchType: "职位",
				options: [{
					value: "选项1",
					label: "计算机",
				}, ],
				//头部公告
				newstypeList1: [{
						id: "39",
						name: "本地动态",
						click: true,
					},
					{
						id: "79",
						name: "热点招考",
						click: false,
					},
					{
						id: "100",
						name: "引才政策",
						click: false,
					},
					{
						id: "101",
						name: " 就业培训",
						click: false,
					},
					{
						id: "43",
						name: "职称评审",
						click: false,
					}
				],
				//职场干货
				newstypeList2: [{
						id: "管理类",
						name: "管理类",
						state: true
					},
					{
						id: "技术类",
						name: "技术类",
						state: false
					},
					{
						id: "销售类",
						name: "销售类",
						state: false
					},
					{
						id: "生产类",
						name: "生产类",
						state: false
					},
					{
						id: "服务类",
						name: "服务类",
						state: false
					},
				],
				auto_id: '',
				com_account: '',
				hylist: [],
				hylist1: [],
				submenushow: false,
				newslist: [],
				val: new Date(),
				companydata: [],
				isActive: false,
				input: "",
				value: "",
				positiondata: [],
				linksdata: [],
				tabMain: [],
				cur: "",
				zpgyg: {},
				zpgyglist: [],
				bannerlist: [],
				userinfo: {},
				usertype: 0,
				userid: 0,
				carousellist: [],
				homeimg: {},
				personnelList: [],
				searchlist: [],
				isCall: 0,
				isRshow: false,
				uid: 0,
			};
		},
		created() {
			this.getDayAdv();
			//获取资讯信息
			this.Getrotationadvlists(); //轮播图
			this.getsearchlist()
			this.Getnewslists(1, this.activeName);
			//this.Getnewslists(2, this.cur);
			//this.Getnewslists(3, "1017"); //招聘预告
			this.Getadlists();
			this.GetHyList();
			this.Gethotcompanylists(); //推荐企业
			this.Gethotposilists(); //岗位推荐
			//this.GetrecPerson()
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
				this.usertype = this.userinfo.userType;
				if (this.usertype == 1)
					this.userid = this.userinfo.per_id;
				else
					this.userid = this.userinfo.com_id;
			}
		},
		mounted() {
			this.getGVerify();
		},
		beforeDestroy() {
			// 销毁定时器，否则可能导致重载此组件时会有多个定时器同时执行，使得滚动变快
			window.clearTimeout(this.timer);
		},
		computed: {
			optionLeft() {
				return {
					step: 0,
					direction: 2, // 0向下 1向上 2向左 3向右
					hoverStop: true,
					openTouch: false,
				};
			},
		},
		methods: {
			logininfo() {
				var _this = this;
				if (isnull(_this.subfrom.mobile)) {
					_this.$message.error("请输入用户名");
					return false;
				}
				if (isnull(_this.subfrom.password)) {
					_this.$message.error("请输入密码");
					return false;
				}
				if (isnull(_this.subfrom.code)) {
					_this.$message.error("请输入验证码");
					return false;
				}
				if (_this.choice3) _this.subfrom.type = 1;
				else _this.subfrom.type = 2;
				var flag = this.GVerifyCode.validate(_this.subfrom.code);
				if (!flag) {
					this.$message({
						message: "验证码错误",
						type: "warning",
					});
					this.GVerifyCode.refresh();
					return false;
				}
				if (_this.choice3) {
					UserLogin(_this.subfrom).then((res) => {
						if (res.success) {
							this.$message({
								message: "登录成功",
								type: "success",
							});
							var rdata = res.data;
							rdata.user.userType = _this.type;
							localStorage.setItem("token", rdata.token);
							localStorage.setItem("userinfo", JSON.stringify(rdata.user));
							if (_this.isback) {
								setTimeout(_this.$router.go(-1), 500);
							} else {
								setTimeout(_this.resolvewin("/", {}, 1), 500);
							}
						} else {
							_this.$message.error(res.msg);
						}
					});
				} else {
					CompanyLogin(_this.subfrom).then((res) => {
						if (res.success) {
							this.$message({
								message: "登录成功",
								type: "success",
							});
							var rdata = res.data;
							rdata.user.userType = _this.type;
							localStorage.setItem("token", rdata.token);
							localStorage.setItem("userinfo", JSON.stringify(rdata.user));
							if (_this.cbxbox) {
								localStorage.setItem("mobile2", _this.subfrom.mobile);
								localStorage.setItem("password2", _this.subfrom.password);
							}
							if (_this.isback) {
								setTimeout(_this.$router.go(-1), 500);
							} else {
								setTimeout(_this.resolvewin("/", {}, 1), 500);
							}
						} else {
							_this.$message.error(res.msg);
						}
					});
				}
			},
			GetHyList() {
				var _this = this;
				GetHyList().then((res) => {
					if (res.success) {
						_this.hylist = res.data;
					}
				})
			},
			mouseover(type, com_account) {
				this.submenushow = true;
				this.auto_id = type;
				this.com_account = com_account;
				this.GetAllHyList(type);
			},
			mouseout() {
				this.submenushow = false;
			},
			GetAllHyList(type) {
				var _this = this;
				var par = {
					cid: type
				}
				GetAllHyList(par).then((res) => {
					if (res.success) {
						_this.hylist1 = res.data;
					}
				})
			},
			onChoice1(e) {
				this.logtext = "企业"
				this.choice1 = e
				this.choice2 = !e
				this.choice3 = false
			},
			getGVerify() {
				this.GVerifyCode = getGVerify({
					id: "canvasCode",
					width: "100",
					height: "40",
					fontSize: "26",
					numSize: 4,
					type: "blend",
				});
			},
			onChoice2(e) {
				this.logtext = "个人"
				this.choice2 = e
				this.choice1 = !e
				this.choice3 = true
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			getDayAdv() {
				var _this = this;
				var par = {
					id: 18,
				};
				GetAdvLinkByType(par).then((res) => {
					if (res.success) {
						if (res.data.length > 0)
							_this.homeimg = res.data[0];
					}
				})
			},
			//首页轮播图
			Getrotationadvlists() {
				var _this = this;
				Getrotationadvlist().then((res) => {
					if (res.success) {
						_this.carousellist = res.data.rows;
					}
				});
			},
			enter(cell) {
				this.isActive = true;
				this.num = cell.coM_ID;
			},
			stringToDates(data) {
				if (isnull(data)) {
					return "";
				}
				return utils.stringToDate(data);
			},
			leave() {
				this.isActive = false;
			},
			//获取资讯信息
			Getnewslists(type, id) {
				var _this = this;
				var par = {
					types: id,
					pageSize: type === 1 ? 7 : 4,
				};
				Getnewslist(par).then((res) => {
					if (res.success) {
						if (type === 1) {
							_this.newslist = res.data.rows;
						} else if (type === 2) {
							_this.tabMain = res.data.rows;
						} else if (type === 3) {
							_this.zpgyg = res.data.rows[0];
							_this.zpgyglist = res.data.rows;
						}
					}
				});
			},
			//推荐人才
			GetrecPerson() {
				var _this = this;
				var par = {
					limit: 6,
					type: 1,
				};
				recPerson(par).then((res) => {
					if (res.success) {
						_this.personnelList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			newstypeMouseover(e, i) {
				this.activeName = e;
				this.newstypeList1.forEach((element) => {
					element.click = false;
				});
				this.newstypeList1[i].click = true;
				if (this.activeName == 0) {} else {
					this.Getnewslists(1, this.activeName);
				}
			},

			newschage(id) {
				if (this.activeName == 0) {} else {
					localStorage.setItem("cTID", id);
				}
				this.goto("/news", {}, 1);
			},
			handleCommand(e) {
				this.searchid = e
				if (e == 1) {
					this.searchType = "公司";
				} else {
					this.searchType = "职位";
				}
			},
			seljob() {
				if (isnull(this.input)) {
					this.$message({
						message: "请输入职位/公司",
						type: "warning",
					});
					return false;
				}
				this.addsearch()
				if (this.searchid == 1) {
					this.resolvewin("/findEnterprise", {
						searchKey: this.input
					}, 1);
				} else {
					this.resolvewin("/jobhunting", {
						searchKey: this.input
					}, 1);
				}
			},
			handleClick(e) {
				if (this.activeName == 0) {
					this.$router.push("/news");
				}
			},

			handleClick1(title) {
				this.newstypeList2.forEach(item => {
					item.state = false
				})
				this.newstypeList2.forEach(item => {
					if (item.name == title.name) {
						item.state = true
					}
				})
				this.cur = title.id;
				this.GetrecPerson();
			},
			//广告banner
			Getadlists() {
				var _this = this;
				Getadlist().then((res) => {
					if (res.success) {
						_this.bannerlist = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//友情链接
			Getadlinklists() {
				var _this = this;
				Getadlinklist().then((res) => {
					if (res.success) {
						_this.linksdata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			Gethotcompanylists() {
				var _this = this;
				var par = {
					pageNumber: 1,
					pageSize: 10,
				};
				Gethotcompanylist(par).then((res) => {
					if (res.success) {
						_this.companydata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			analysisjob(datas) {
				var data = [];
				if (datas) {
					datas.split(",").forEach((item) => {
						if (item) {
							if (data.length < 3) {
								var a = item.split("|");
								if (a.length > 1) {
									var sp = a[1].split("-");
									data.push({
										postname: a[0],
										salary: this.setsalary(sp[0], sp[1]),
									});
								}
							}
						}
					});
				}
				return data;
			},
			setcompanylogo(url, data) {
				return isnull(url) ?
					require(`@/assets/img/${data}.png`) :
					this.defimgurl + url;
			},
			//岗位推荐
			Gethotposilists() {
				var _this = this;
				Gethotposilist({
					pageSize: 84
				}).then((res) => {
					if (res.success) {
						_this.positiondata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			linkto(url) {
				this.resolvewin(url, {}, 3);
			},
			getsearchlist() {
				var _this = this;
				var per = {
					cid: 1,
					pageSize: 5
				}
				getsearch(per).then((res) => {
					if (res.success)
						this.searchlist = res.data
				})
			},
			addsearch() {
				var _this = this;
				var per = {
					idType: _this.usertype,
					userId: _this.userid,
					searchType: _this.searchType == "职位" ? 1 : 2,
					searchKey: _this.input,
				}
				addsearch(per).then((res) => {})
			},
			goto(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},
			changeTime(date, date1) {

			},
			ckislogin(url, data) {
				url = `/${this.userinfo.userType === 1 ? "usercenter" : "companycenter"
				}?type=0`;
				var par = {
					type: 0
				};
				if (isnull(localStorage.getItem("token"))) {
					par = data;
					url = "/login";
				}
				this.resolvewin(url, par, 1);
			},
			notice(data) {
				var zpgyglist = this.zpgyglist;
				zpgyglist.forEach((element) => {
					if (element.dYXQ) {
						var date = element.dYXQ.split(" ")[0];
						if (date == data.day) {
							data.isSelected = true;
						}
					}
				});

				return data;
			},
			setsalary(a, b) {
				return utils.setsalary(a, b);

			},
		},
	};
</script>
<style lang="scss" scoped>
	.fixedright {
		position: fixed;
		top: 260px;
		right: 10px;
	}

	.home-banner {
		width: 100%;
		height: 180px;
		margin: 0px;
		padding: 0px;
		position: relative;

		// background: url(../assets/img/banner.jpg) center top no-repeat;
		.banner-content {
			width: 1200px;
			margin: 0px auto;
			height: 180px;
			// background: url(../assets/img/banner.jpg) center top no-repeat;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.home-login-mode {
				height: 108px;
				border-radius: 8px;
				background: #fff;
				padding: 16px 24px;
				display: flex;
				align-items: center;

				.login-scancode {
					width: 96px;
					height: 108px;
					display: flex;
					flex-direction: column;

					img {
						width: 100%;
					}

					p {
						padding: 0;
						margin: 0;
						font-size: 14px;
						color: #333;
					}
				}

				.login-cell {
					flex: 1;
					width: 100%;
					display: flex;
					flex-direction: column;
					font-size: 12px;
					padding-left: 24px;
					margin-left: 24px;
					border-left: 1px solid #eee;

					.login-title {
						font-size: 14px;
						color: #333;
					}

					.login-item {
						cursor: pointer;
						margin-top: 10px;

						a {
							color: #787878;
							text-decoration: none;
							display: flex;
							align-items: center;

							i {
								font-size: 14px;
								width: 14px;
								text-align: left;
								margin-right: 5px;
							}
						}
					}
				}
			}
		}
	}

	.home {
		height: 100%;
	}

	.home-contain {
		height: 100%;
	}

	.home-search {
		width: 100%;
		height: 130px;
		background: url(../assets/img/souso_bg.png) no-repeat;
		background-size: 100% 100%;
		position: relative;
	}

	.home-select {
		width: 1200px;
		height: 130px;
		margin: 0px auto;
		margin-bottom: 16px;

		display: flex;
		align-items: center;
		position: relative;
		// justify-content: center;

		.select-contain {
			width: 100%;
			// padding: 24px;
			//display: flex;

			/deep/.el-button--primary {
				color: #898989;
				background-color: #ffff;
				border-color: #ffff;

			}

			/deep/.el-dropdown .el-dropdown__caret-button::before {
				background: #fff;
			}

			.el-form {
				//margin-left: 35%;
				float: left;
				width: 880px;
				background: #fff;
				height: 40px;
				border-radius: 4px;
				margin-left: 160px;

				// display: flex;
				/deep/.el-form-item__content {
					display: flex;
					background: #fff;
					border-radius: 0 4px 4px 0;
				}

				.el-form-item::v-deep {
					margin-bottom: 0;
				}

				.el-select ::v-deep {
					.el-input {
						// padding-top: 4px;
						padding-bottom: 4px;

						.el-input__inner {
							border: none;
							border-radius: 0;
							font-size: 14px;

						}

						.el-input__inner::placeholder {
							color: #333;
						}
					}
				}

				.el-input ::v-deep {
					padding: 0;
					border: none;
					height: 40px;

					.el-input__inner {
						border: none;
						border-radius: 0;
						border-left: 1px solid #eee;
					}
				}



				.el-button {
					width: 100%;
					height: 41px;
					color: #fff;
					text-align: center;
					border-radius: 0 4px 4px 0;
					// padding-top: 16px;
					border: 1px solid #F66308;
					font-size: 16px;
					background: #F66308;
				}
			}

			.resume {
				.el-button {
					width: 73%;
					height: 40px;
					// line-height: 40px;
					margin-left: 14px;
					border: 1px solid #F66308;
					color: #fff;
					text-align: center;
					border-radius: 4px;
					// padding-top: 16px;
					// padding-bottom: 10px;
					font-size: 16px;
					background: #F66308;

					/deep/span {
						display: flex;
						align-items: center;
					}
				}
			}

			.hot-word {
				text-align: left;
				font-size: 16px;
				color: #787878;

				a {
					padding: 0px 12px;
					color: #409eff;
					text-decoration: none;
				}
			}
		}

		.contact {
			width: 20%;
			height: 100px;

			.img {
				height: 100px;
			}
		}
	}

	.hot_search {
		display: flex;
		position: absolute;
		top: 94px;
		right: 0;
		width: 1038px;

		.position {
			color: rgba(255, 255, 255, 100);
			font-size: 13px;
			font-family: SourceHanSansSC-light;
			width: 70%;

			span {
				margin-right: 10px;
				cursor: pointer;
			}

			a {
				margin-right: 10px;
			}
		}
	}

	.home-news {
		width: 1200px;
		margin: 0px auto;
		margin-top: 16px;
		// overflow: hidden;
		height: 100%;

		.news-contain {
			background: #fff;
			display: flex;
			height: 100%;

			.el-row {
				display: flex;
				align-items: stretch;

				.el-col {
					display: flex;
					align-items: stretch;
				}
			}

			.news-content {
				padding: 12px 2.5%;
				padding-right: 0;
				background: #fff;
				border-radius: 4px;
				width: 95%;
				height: 277px;

				.el-tabs ::v-deep {
					.el-tabs__nav-scroll {
						.el-tabs__nav {
							width: 100%;
							display: flex;
							justify-content: space-around;
							// padding-bottom: 12px;

							.el-tabs__active-bar {
								bottom: 0px;
								background: #e4e7ed;
								border-radius: 0px 8px 8px 0px;
							}

							.el-tabs__item {
								width: 100%;
								height: 35px;
								line-height: 35px;
								flex: 1;
								text-align: center;
								padding-left: 30px;
								color: #898989;
								background-color: rgba(238, 238, 238, 100);
								// border-right: 1px solid #e4e7ed;
							}

							a {
								color: #898989;
								text-decoration: blink;
							}

							a:hover {
								color: #347af8
							}

							.is-active {
								background: #ffffff;
								color: rgba(52, 122, 248, 100);
								font-size: 15px;
								font-weight: bold;
								font-family: SourceHanSansSC-medium;
							}

							.el-tabs__item:last-child {
								border-right: none;
							}
						}
					}

					.el-tabs__content {
						.news-cell {
							height: 212px;

							.icon_yuan {
								width: 8px;
								height: 8px;
								line-height: 20px;
								background-color: rgba(40, 142, 252, 100);
								text-align: center;
								border-radius: 25px;
							}

							.news-item {
								cursor: pointer;
								display: flex;
								align-items: center;
								text-align: left;
								font-size: 14px;
								text-decoration: none;
								margin-bottom: 12px;
								color: #787878;
								justify-content: space-between;
								height: 20px;
								line-height: 20px;

								.news-title {
									flex: 1;
									max-width: 100%;
									overflow: hidden;
									text-overflow: ellipsis;
									-o-text-overflow: ellipsis;
									-webkit-text-overflow: ellipsis;
									-moz-text-overflow: ellipsis;
									white-space: nowrap;
									margin-left: 5px;
								}

								.news-time {
									margin-left: 12px;
								}
							}

							.news-item:last-child {
								margin-bottom: 0;
							}

							.news-item:hover {
								color: #409eff;
								background: #f2f2f2;
							}
						}
					}
				}

				.tabimg {
					width: 25px;
					height: 25px;
					margin-right: 5px;
				}
			}

			.news-recruit {
				// padding: 12px 10px 2.5%;
				padding-top: 12px;
				padding-left: 3%;
				padding-right: 2.5%;
				padding-bottom: 12px;
				background: #fff;
				border-radius: 4px;
				width: 100%;
				// display: flex;

				.recruit-content {
					width: 260px;
					margin-right: 16px;
					cursor: pointer;

					.recruit-tab {
						padding: 3px 0 15px 0;
						height: 18px;
						line-height: 18px;
						text-align: left;
						font-size: 14px;
						color: #333;

						span {
							padding: 0px 5px;
							border-radius: 4px;
							background: #ff0000;
							color: #fff;
							font-size: 12px;
							display: block;
							float: left;
							margin-right: 12px;
						}
					}

					.recruit-banenr {
						width: 260px;
						height: 170px;
						overflow: hidden;

						img {
							width: 260px;
							height: 170px;
						}
					}

					.recruit-text {
						padding: 12px 10px;
						border: 1px solid #eee;
						border-top: none;
						line-height: 28px;
						text-align: left;
						font-size: 14px;
						display: flex;
						flex-direction: column;

						.recruit-title {
							flex: 1;
							width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							-o-text-overflow: ellipsis;
							-webkit-text-overflow: ellipsis;
							-moz-text-overflow: ellipsis;
							white-space: nowrap;
						}

						.recruit-data {
							display: flex;
							align-items: center;

							.el-icon-time {
								font-size: 22px;
								margin-right: 5px;
							}
						}
					}
				}

				.recruit-calendar {
					flex: 1;
					width: 100%;

					.el-calendar ::v-deep {
						.el-calendar__header {
							padding: 0px;
							font-size: 12px;
							padding-bottom: 8px;

							.el-button--mini {
								padding: 4px 0px;
							}
						}

						.el-calendar__body {
							padding: 0px;

							.el-calendar-table thead th {
								font-size: 14px;
								padding: 4px 0;
							}

							.el-calendar-table .el-calendar-day {
								padding: 8px 0;
								height: 26px;
								font-size: 13px;
							}
						}
					}
				}
			}

			.title_left {
				width: 25%;
				height: 100%;
				background-color: #0681fc;
				position: relative;

				.el-menu {
					border-right: none;
					z-index: 999;
					padding: 0 10px;

					.title {
						font-size: 15px;
						color: rgba(16, 16, 16, 100);
						font-family: SourceHanSansSC-regular;
						line-height: 60px;
						display: block;
						height: 60px;
					}

					.subtitle {
						width: 100%;
						overflow: hidden;
						font-size: 12px;
						color: rgba(137, 137, 137, 100);
						font-family: SourceHanSansSC-regular;
						line-height: 30px;
						height: 30px;
					}
				}

				.el-menu>div {
					position: relative;
					padding: 0 20px;

					i {
						position: absolute;
						top: 50%;
						right: 20px;
						margin-top: -7px;
						transition: transform .3s;
						font-size: 12px;
					}
				}

				.el-menu>div:hover {
					cursor: pointer;
					background-color: #ecf5ff;
				}

				.el-submenu {
					position: absolute;
					top: 0px;
					left: 100%;
					width: 100%;
					z-index: 996;
					background-color: #fff;
					font-size: 12px;
					padding: 10px;

					.subtitle {
						font-size: 15px;
						color: rgba(16, 16, 16, 100);
						font-family: SourceHanSansSC-regular;
						margin-bottom: 15px;
						font-weight: bold
					}

					a {
						display: inline-block;
						padding: 5px;
					}

					a:hover {
						color: #409eff;
						background: #f2f2f2;
					}
				}
			}

			.title_center {
				width: 75%;

				.onlog {
					cursor: pointer;
					color: rgba(16, 16, 16, 100);
					font-size: 16px;
					background-color: #fff;
					font-family: SourceHanSansSC-regular;
					font-weight: bold;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 45%;
					text-align: center;

				}

				.onlog1 {
					color: rgba(137, 137, 137, 100);
					background-color: #F8F8F8;
				}

				.sanjiao {
					width: 0;
					height: 0;
					border-color: #fff #F8F8F8;
					border-width: 0px 50px 60px 0px;
					border-style: solid;

				}

				.sanjiao1 {
					width: 0;
					height: 0;
					border-color: #F8F8F8 #fff #fff #F8F8F8;
					border-width: 30px 20px 30px 20px;
					border-style: solid;

				}


				.logSign {

					.input {
						width: 90%;
						margin-left: 5%;

						margin-top: 16px;
					}

					.code {
						width: 8pc;
						height: 40px;
						height: 40px;
						position: absolute;
						right: 0;
					}

					.el-input__inner ::v-deep {
						border-radius: 25px;
						background-color: #F5F5F5;
						position: relative;

					}

					input::-webkit-input-placeholder,
					textarea::-webkit-input-placeholder {
						color: #888;
						font-size: 14px;
					}

					input:-moz-placeholder,
					textarea:-moz-placeholder {
						color: #888;
						font-size: 14px;
					}

					input::-moz-placeholder,
					textarea::-moz-placeholder {
						color: #888;
						font-size: 14px;
					}

					input:-ms-input-placeholder,
					textarea:-ms-input-placeholder {
						color: #888;
						font-size: 14px;
					}
				}

				.but {
					width: 100%;
					text-align: center;
					margin-top: 15px;
				}
			}
		}
	}

	.home-advertisement {
		width: 1200px;
		margin: 0px auto;
		margin-top: 5px;
		margin-bottom: 10px;

		img {
			padding-top: 5px;
		}

	}

	.home-company {
		width: 1200px;
		margin: 0px auto;
		background: #f8f8f8;
		padding: 15px 0;
		border-radius: 4px;

		.company-content {
			.company-title {
				font-size: 18px;
				color: #333;
				margin-bottom: 16px;

				.more {
					font-size: 14px;
					color: #898989;
					cursor: pointer;
				}

				.name {
					color: rgba(16, 16, 16, 100);
					font-size: 20px;
					font-family: SourceHanSansSC-bold;
					font-weight: bold;

					img {
						width: 35px;
						height: 35px;
						margin-right: 5px;
					}
				}
			}

			.company-cell {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-around;
				height: auto;

				.company-item {
					cursor: pointer;
					width: 230px;
					height: 135px;
					// padding: 12px 0;
					background: #fff;
					margin-bottom: 15px;
					position: relative;
					overflow: hidden;

					.company-logo {
						height: 50px;
						padding-top: 12px;
						text-align: center;

						img {
							height: 50px;
						}
					}

					.company-name {
						font-size: 14px;
						line-height: 32px;
						color: #333;
						text-align: center;
						width: 70%;
						margin: 0 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.company-num {
						padding: 0px 8px;
						border-radius: 2px;
						border: 1px solid #eee;
						font-size: 12px;
						background: #fff;
						width: 180px;
						margin: 0px auto;
						text-align: center;
						height: 35px;
						line-height: 3;

						b {
							color: #ff0000;
						}
					}

					.company-post {
						position: absolute;
						padding: 16px;
						left: 0;
						right: 0;
						top: 140px;
						height: 110px;
						background: rgba($color: #000000, $alpha: 0.6);
						transition: all 0.5s;

						.post-item {
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-bottom: 10px;

							.post-name {
								color: #fff;
								font-size: 12px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								max-width: 190px;
							}

							.post-salary {
								font-size: 12px;
								color: #ff0000;
								max-width: 50px;
							}
						}

						.post-more {
							text-align: center;
						}
					}

					.hoveractive {
						top: 0;
					}
				}
			}
		}
	}

	.home-position {
		width: 1170px;
		margin: 0px auto;
		background: #fff;
		padding: 15px;
		border-radius: 4px;
		margin-bottom: 20px;

		.position-content {
			.position-title {
				font-size: 18px;
				color: #000;
				margin-bottom: 16px;

				.more {
					font-size: 14px;
					color: #898989;
					cursor: pointer;

				}

				.name {
					color: rgba(16, 16, 16, 100);
					font-size: 20px;
					font-family: SourceHanSansSC-bold;
					font-weight: bold;

					img {
						width: 35px;
						height: 35px;
						margin-right: 5px;
					}
				}
			}

			.position-cell {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: stretch;
				overflow: hidden;

				.position-item {
					width: 360px;
					padding: 10px 15px;
					position: relative;
					// background: #f2f2f2;
					// margin-bottom: 10px;
					border-radius: 2px;
					cursor: pointer;
					float: left;

					.position-hd {
						font-size: 14px;
						color: #333;
						display: flex;
						// margin-bottom: 2px;
						align-items: center;
						float: left;
						margin-right: 10px;

						.post-name {
							// font-weight: bold;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.post-salary {
							max-width: 70px;
							font-size: 16px;
							text-align: right;
							color: #ff6600;
							font-weight: bold;
						}

						.img {
							width: 30px;
							height: 30px;
							margin-right: 10px;
						}
					}

					.position-bd {
						font-size: 14px;
						color: #0681fc;

						text-align: left;

						.post-info {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}

						.desc {
							height: 30px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: normal;
						}

						span {
							padding: 0 4px;
							border-right: 1px solid #eee;
						}

						span:first-child {
							padding-left: 0;
						}

						span:last-child {
							border: none;
						}
					}

					.position-card {
						display: flex;
						align-items: center;
						text-align: left;
						padding-top: 12px;
						border-top: 1px dashed #ddd;

						.card-img {
							width: 32px;
							line-height: 32px;
							height: 32px;
							display: flex;
							align-items: center;
							margin-right: 12px;
							background: #fff;
							border-radius: 2px;

							img {
								width: 100%;
							}
						}

						.card-body {
							flex: 1;

							.card-name {
								font-size: 14px;
								word-break: keep-all;
								overflow: hidden;
								text-overflow: ellipsis;
								width: 169px;
								white-space: normal;
								color: #333;
							}

							.card-posthot {
								font-size: 12px;
								color: #787878;

								b {
									color: #409eff;
								}
							}
						}
					}
				}
			}

			.position-more {
				width: 200px;
				height: 32px;
				line-height: 32px;
				color: #fff;
				font-size: 14px;
				margin: 0px auto;
				text-align: center;

				a {
					text-decoration: none;
					color: #fff;
					background: #409eff;
					transition: all 0.5s;
					display: block;
				}

				a:hover {
					background: rgba($color: #409eff, $alpha: 0.7);
				}
			}
		}
	}

	.home-links {
		width: 1170px;
		margin: 0px auto;
		background: #fff;
		padding: 15px;
		border-radius: 4px;
		margin-bottom: 20px;
		padding-bottom: 0;

		.links-content {
			.links-title {
				font-size: 18px;
				color: #000;
				margin-bottom: 16px;
			}

			.links-cell {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: stretch;

				.cell-item {
					width: 135px;
					height: 30px;
					padding: 30px 0;
					position: relative;
					background: #f2f2f2;
					margin-bottom: 16px;
					display: flex;
					justify-content: center;

					img {
						height: 30px;
						transition: all 0.5s;
					}
				}

				.cell-item:hover img {
					transform: scale(1.1);
				}
			}
		}
	}

	.home-workplace {
		width: 1170px;
		margin: 0px auto;
		background: #fff;
		padding: 0 15px;
		border-radius: 4px;
		margin-bottom: 5px;
		padding-bottom: 0;


		.workplace-comtent {

			.position-title {
				font-size: 18px;
				color: #000;
				margin-bottom: 16px;
				display: flex;

				position: relative;

				.more {
					font-size: 14px;
					color: #898989;
					cursor: pointer;

				}

				.workplace-title {
					margin-right: 24px;
					font-size: 16px;
					margin: 10px 24px 10px 0;
					color: #000;
					font-weight: bold;
				}

				.name {
					overflow: hidden;
					color: rgba(16, 16, 16, 100);
					font-size: 20px;
					font-family: SourceHanSansSC-bold;
					font-weight: bold;

					img {
						width: 24px;
						height: 24px;
						margin-right: 5px;
					}
				}
			}




			.workplace-nav {
				flex: 1;
				display: flex;
				cursor: pointer;
				margin-top: 15px;
				margin-left: 86px;
				justify-content: flex-end;

				.workplace-item {
					margin: 10px 0;
					padding: 0px 10px;
					margin-right: 16px;
					padding-bottom: 12px;
					color: rgba(16, 16, 16, 100);
					font-size: 14px;

				}



				.workplace-nav {
					flex: 1;
					display: flex;
					cursor: pointer;
					margin-top: 15px;
					margin-left: 86px;

					.workplace-item {
						margin: 10px 0;
						padding: 0px 10px;
						margin-right: 16px;
						padding-bottom: 12px;
						color: rgba(16, 16, 16, 100);
						font-size: 14px;
					}

					.active {
						// padding-bottom: 1px;
						// border-bottom: 3px solid #409eff;
						// color: #409eff;
					}


					.workplace-bd {
						margin-top: 9px;

						.workplace-cell {
							display: flex;
							align-items: stretch;
							justify-content: space-between;
							flex-wrap: wrap;

							.workplace-item {
								cursor: pointer;
								width: 490px;
								height: 84px;
								padding: 16px 0;
								display: flex;
								align-items: center;

								.workplace-img {
									width: 140px;
									height: 84px;
									display: flex;
									align-items: center;
									justify-content: center;
									margin-right: 16px;

									img {
										height: 84px;
									}
								}

								.workplace-body {
									flex: 1;
									width: 100%;
									overflow: hidden;
									text-align: left;

									.workplace-name {
										font-size: 14px;
										color: #333;
										font-weight: bold;
										margin-bottom: 8px;
										text-overflow: ellipsis;
										-o-text-overflow: ellipsis;
										-webkit-text-overflow: ellipsis;
										-moz-text-overflow: ellipsis;
										white-space: nowrap;
									}

									.workplace-detail {
										font-size: 12px;
										color: #787878;
										line-height: 20px;
										overflow: hidden;
										word-break: break-all;
										display: -webkit-box;
										-webkit-line-clamp: 3;
										-webkit-box-orient: vertical;
										overflow: hidden;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.personnel {
		// flex: 1;
		display: flex;
		width: 1200px;
		margin: 0px auto;
		padding-bottom: 15px;
		justify-content: space-between;

		overflow: hidden;

		// overflow-x: scroll; // 子元素的宽度大于父元素的即可滚动
		// overflow-y: hidden;
		.personnel_details {
			width: 180px;
			background-color: #fff;
			text-align: center;
			color: rgba(137, 137, 137, 100);
			font-size: 14px;
			padding: 5px;
			cursor: pointer;

			.resume-logo {
				width: 30px;
				height: 30px;
				background: #E7F3FF;
				padding: 10px;
				border-radius: 50%;
				display: inline-block;

				img {
					width: 30px;
					height: 30px;
					border-radius: 50%;
				}
			}

			.name {
				color: rgba(16, 16, 16, 100);
				font-size: 16px;
			}

			.introduce {
				width: 100%;
				display: flex;
				justify-content: center;
				margin-top: 10px;
				font-size: 12px;

				span {
					margin-right: 10px;
				}
			}

			.intention {
				margin-left: 16px;
				display: flex;
				justify-content: flex-start;
				margin-top: 12px;
			}

		}
	}

	.red {
		color: #ff0000;
	}

	.weight {
		font-weight: bold;
	}

	.is-selected {
		color: #1989fa;
	}
</style>
